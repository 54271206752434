<template>
  <div class="layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">페스티벌 축제자료</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_02_box">
      <form action="#">
        <table class="layer_01_table">
          <colgroup>
            <col style="width: 158px" />
            <col style="width: 760px" />
          </colgroup>
          <tr class="layer_tbl" v-if="id">
            <td class="layer_tbl_title pb_15">등록일시</td>
            <td class="pb_15">{{ registerDate }}</td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">회원명</td>
            <td class="pb_15" v-if="id">{{ user.name }}</td>
            <td class="pb_15" v-else>
              <input
                type="text"
                class="text_box_05"
                readonly
                v-model="user.name"
              />
              <base-button type="upload" class="ml_30" @click="callUser"
                >불러오기</base-button
              >
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">회원이메일주소</td>
            <td class="pb_15" v-if="id">{{ user.email }}</td>
            <td class="pb_15" v-else>
              <input
                type="text"
                class="text_box_05"
                readonly
                v-model="user.email"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">축제명</td>
            <td class="pb_15">
              <input type="text" class="text_box_02" v-model="festivalName" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">축제 기간</td>
            <td class="pb_15">
              <date-picker
                v-model="festivalStartDate"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker
                v-model="festivalEndDate"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">축제정보</td>
            <td class="pb_15">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="selectFile.newInfoName"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".doc*, .hwp*"
                id="info_upload"
                ref="refInfo"
                @change="previewFile('info')"
              />
              <label for="info_upload" class="file_btn">찾아보기</label>
              <div
                v-if="selectFile.infoName"
                class="img_name_box stamp_img_n_01"
              >
                <a
                  :href="`${fileDown_url}${selectFile.infoName}?name=${id}_축제정보`"
                  download
                  target="_blank"
                  class="img_name"
                >
                  <base-button
                    type="download"
                    style="height: 43px; line-height: 0"
                    >다운로드</base-button
                  >
                </a>
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">사진자료</td>
            <td class="pb_15">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="selectFile.newPictureName"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept="zip"
                id="picture_upload"
                ref="refPicture"
                @change="previewFile('picture')"
              />
              <label for="picture_upload" class="file_btn">찾아보기</label>
              <div
                v-if="selectFile.pictureName"
                class="img_name_box stamp_img_n_01"
              >
                <a
                  :href="`${fileDown_url}${selectFile.pictureName}?name=${id}_사진자료`"
                  download
                  target="_blank"
                  class="img_name"
                >
                  <base-button
                    type="download"
                    style="height: 43px; line-height: 0"
                    >다운로드</base-button
                  >
                </a>
              </div>
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15">처리담당자</td>
            <td class="pb_15">
              <input type="text" class="text_box_02" v-model="person" />
            </td>
          </tr>
          <tr class="layer_tbl" v-if="id">
            <td class="layer_tbl_title pb_15">진행상태</td>
            <td class="pb_15">
              <base-select
                :options="states"
                v-model="selectedState"
                placeholder="선택"
              />
            </td>
          </tr>
        </table>
      </form>
    </div>
    <div class="btn_center_wrapper">
      <base-button type="primary-lg" @click="edit">{{
        id ? "수정" : "등록"
      }}</base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
import BaseSelect from "./core/BaseSelect.vue";
import UserCall from "@/components/UserCall.vue";
export default {
  components: {
    BaseButton,
    BaseSelect,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      festivalName: "",
      registerDate: "",
      orderId: 0,
      festivalStartDate: this.$date().format("YYYY-MM-DD"),
      festivalEndDate: this.$date().format("YYYY-MM-DD"),
      person: "",
      manager: this.getUser ? this.getUser.name : "",
      states: [
        { name: "주문(결제완료)", value: "1" },
        { name: "검수 중", value: "2" },
        { name: "보완요청", value: "3" },
        { name: "개발진행", value: "4" },
        { name: "개발완료", value: "5" },
        { name: "운영 중", value: "6" },
        { name: "운영 종료", value: "7" },
        { name: "취소(환불)", value: "8" },
      ],
      selectedState: "",
      selectFile: {
        info: null,
        infoName: "",
        newInfoName: "",
        picture: null,
        pictureName: "",
        newPictureName: "",
      },
      info: "",
      picture: "",
      fileDown_url:
        "https://stamppop.nextinnovation.kr/api/v3/admin/file/view/",
      user: {
        name: "",
        email: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
  },
  created() {
    this.fetch();
  },
  methods: {
    async edit() {
      if (this.selectFile.info) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.info);
        await client.fileAdd(form).then(
          (response) => {
            this.info = response.data.name;
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        if (this.selectFile.infoName) {
          this.info = this.selectFile.infoName;
        } else {
          this.info = null;
        }
      }
      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            this.picture = response.data.name;
            console.log(response.data.name);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        if (this.selectFile.pictureName) {
          this.picture = this.selectFile.pictureName;
        } else {
          this.picture = null;
        }
      }
      /* XXX 파일 업로드 오류 임시 파일명 등록 */
      if (this.selectFile.info) {
        if (!this.info) {
          this.info = this.selectFile.info.name;
        }
      }
      if (this.selectFile.picture) {
        if (!this.picture) {
          this.picture = this.selectFile.picture.name;
        }
      }

      let params = {
        userId: this.user.id,
        orderId: this.orderId,
        festivalName: this.festivalName,
        festivalStartDate: this.festivalStartDate,
        festivalEndDate: this.festivalEndDate,
        festivalInfoFile: this.info,
        festivalPictrueFile: this.picture,
        person: this.person,
        status: this.id ? this.selectedState : undefined,
      };
      if (!this.user.name) {
        alert("회원명을 입력해주세요");
      } else if (!this.festivalName) {
        alert("축제명을 입력해주세요");
      } else if (!this.selectFile.info && !this.selectFile.picture) {
        alert("축제정보 또는 사진자료를 입력해주세요");
      } else {
        if (this.id) {
          await client.referenceModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          await client.referenceAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "결제내역/진행현황이 정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    async fetch() {
      await client.referenceFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.festivalName = this.id ? data.festivalName : "";
          this.registerDate = this.id
            ? this.$d(new Date(data.registerDate), "short")
            : "";
          this.user.id = this.id && data.user ? data.user.id : "";
          this.orderId = this.id && data.order ? data.order.id : 0;
          this.user.name = this.id && data.user ? data.user.name : "";
          this.user.email = this.id && data.user ? data.user.email : "";
          this.person = data.person ? data.person : "";
          this.festivalStartDate = this.id
            ? data.festivalStartDate
            : this.$date().format("YYYY-MM-DD");
          this.festivalEndDate = this.id
            ? data.festivalEndDate
            : this.$date().format("YYYY-MM-DD");
          this.selectedState = data.status;
          this.selectFile.infoName =
            this.id && data.festivalInfoFile ? data.festivalInfoFile : "";
          this.selectFile.pictureName =
            this.id && data.festivalPictrueFile ? data.festivalPictrueFile : "";
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async fetchUserId(userId) {
      this.user.id = userId.userId;
      await client.userFindone(this.user.id).then((response) => {
        const { data } = response;
        console.log(data);
        this.user.name = data.name;
        this.user.email = data.email;
      });
    },
    callUser() {
      this.$modal.show(
        UserCall,
        {},
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.fetchUserId(response.params);
            }
          },
        }
      );
    },
    previewFile(type) {
      let selectFile = null;
      if (type == "info") {
        selectFile = this.$refs.refInfo;
      } else if (type == "picture") {
        selectFile = this.$refs.refPicture;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );

        fileExt = fileExt.toLowerCase();
        if (
          !["txt"].includes(fileExt) &&
          this.selectFile[type].size <= 10485760
        ) {
          if (type == "picture") {
            this.selectFile.newPictureName = this.selectFile["picture"].name;
          } else if (type == "info") {
            this.selectFile.newInfoName = this.selectFile["info"].name;
          }
        } else {
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: "파일을 다시 선택해주세요.",
            },
            {
              width: 400,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
          this.selectFile[type] = null;
        }
      } else {
        this.selectFile[type] = null;
      }
    },
  },
};
</script>
