<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">축제 자료</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 158px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">등록일시</td>
            <td class="box_style_02">
              {{ registerDate }}
            </td>
            <td class="box_style_02 list_title_style">회원명</td>
            <td class="box_style_02">
              {{ user.name }}
            </td>
            <td class="box_style_02 list_title_style">회원이메일주소</td>
            <td class="box_style_02">
              {{ user.email }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">축제명</td>
            <td class="box_style_02">
              {{ festivalName }}
            </td>
            <td class="box_style_02 list_title_style">축제 기간</td>
            <td
              class="box_style_02"
              v-if="
                festivalStartDate != '0000-00-00' &&
                festivalEndDate != '0000-00-00'
              "
            >
              {{
                $d(new Date(festivalStartDate), "short") +
                "~" +
                $d(new Date(festivalEndDate), "short")
              }}
            </td>
            <td class="box_style_02" v-else>-</td>
            <td class="box_style_02 list_title_style">진행상태</td>
            <td class="box_style_02">
              {{ selectedState }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">축제정보</td>
            <td class="box_style_02">
              <a
                v-if="festivalInfoFile"
                :href="`${API_URL}${SERVICE_API_URL}/file/view/${festivalInfoFile}?name=${id}_축제정보`"
                target="_blank"
                download
                ><base-button
                  type="download"
                  style="height: 43px; line-height: 0"
                  >다운로드</base-button
                ></a
              >
            </td>
            <td class="box_style_02 list_title_style">사진자료</td>
            <td class="box_style_02">
              <a
                v-if="festivalPictureFile"
                :href="`${API_URL}${SERVICE_API_URL}/file/view/${festivalPictureFile}?name=${id}_사진자료`"
                target="_blank"
                :download="`${regDateString}_${festivalName}`"
                ><base-button
                  type="download"
                  style="height: 43px; line-height: 0"
                  >다운로드</base-button
                ></a
              >
            </td>
            <td class="box_style_02 list_title_style">처리담당자</td>
            <td class="box_style_02">
              {{ person }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      festivalName: "",
      registerDate: "",
      regDateString: "",
      API_URL: API_URL,
      SERVICE_API_URL: SERVICE_API_URL,
      festivalStartDate: this.$date().format("YYYY-MM-DD"),
      festivalEndDate: this.$date().format("YYYY-MM-DD"),
      manager: this.getUser ? this.getUser.name : "",
      states: [
        { name: "주문(결제완료)", value: "1" },
        { name: "검수 중", value: "2" },
        { name: "보완요청", value: "3" },
        { name: "개발진행", value: "4" },
        { name: "개발완료", value: "5" },
        { name: "운영 중", value: "6" },
        { name: "운영 종료", value: "7" },
        { name: "취소(환불)", value: "8" },
      ],
      selectedState: "",
      festivalInfoFile: "",
      festivalPictureFile: "",
      user: {
        name: "",
        email: "",
        id: "",
      },
      person: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.referenceFindone(this.id).then(
        (response) => {
          const { data } = response;
          // console.log(data);
          if (data.status == 1) {
            data.status = "주문(결제완료)";
          } else if (data.status == 2) {
            data.status = "검수 중";
          } else if (data.status == 3) {
            data.status = "보완요청";
          } else if (data.status == 4) {
            data.status = "개발진행";
          } else if (data.status == 5) {
            data.status = "개발완료";
          } else if (data.status == 6) {
            data.status = "운영 중";
          } else if (data.status == 7) {
            data.status = "운영 종료";
          } else if (data.status == 8) {
            data.status = "취소(환불)";
          }
          this.festivalName = data.festivalName;
          this.registerDate = this.$date(new Date(data.registerDate)).format(
            "YYYY.MM.DD."
          );
          this.regDateString = this.$date(new Date(data.registerDate)).format(
            "YYYYMMDD"
          );
          this.user = data.user;
          this.festivalStartDate = data.festivalStartDate;
          this.festivalEndDate = data.festivalEndDate;
          this.selectedState = data.status;
          this.festivalInfoFile = data.festivalInfoFile;
          this.festivalPictureFile = data.festivalPictrueFile;
          this.person = data.person;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    downloadPicture(date, name) {
      console.log(date, name);
    },
  },
};
</script>
